import {
	IResultGetOneExchangeRate,
	IResultLastExchangeRate,
} from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const getLastExchange = async (): Promise<IResultLastExchangeRate> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/exchangeRate/public/last`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: [], message: '' };
			}
		}

		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};

export const getExchangePtUser =
	async (): Promise<IResultGetOneExchangeRate> => {
		try {
			const headers = await commonRequestHeaders();
			const apiUrl = `${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/exchangeRate/private/exchange_pt`;

			const response = await fetch(apiUrl, {
				method: 'GET',
				credentials: 'include',
				headers,
			});

			let responseData: IResultGetOneExchangeRate | null = null;
			try {
				responseData = await response.json();
			} catch (jsonError) {
				throw new Error(`Invalid JSON response from API (${response.status})`);
			}

			if (!response.ok) {
				const errorMessage =
					responseData?.message ||
					`Request failed with status ${response.status}`;

				if (response.status === 401) {
					return { status: 401, data: 0, message: 'Unauthorized' };
				}

				throw new Error(errorMessage);
			}

			if (
				!responseData ||
				typeof responseData !== 'object' ||
				!('data' in responseData)
			) {
				throw new Error('Invalid API response structure');
			}

			if (responseData.result === 2) {
				throw new Error(responseData.message || 'Unexpected API error');
			}

			return {
				status: 200,
				data: responseData.data,
				message: responseData.message || 'Success',
			};
		} catch (error: unknown) {
			const errorMessage =
				error instanceof Error ? error.message : 'Unknown error occurred';

			throw new Error(errorMessage);
		}
	};
