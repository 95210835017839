import { IResultGetByTypeBusinessRules } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const getBusinessRuleByType = async (
	type: string
): Promise<IResultGetByTypeBusinessRules> => {
	try {
		const headers = await commonRequestHeaders();
		const apiUrl = `${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/businessRules/get/type/${type}`;

		const response = await fetch(apiUrl, {
			method: 'GET',
			credentials: 'include',
			headers,
		});

		let responseData: IResultGetByTypeBusinessRules | null = null;
		try {
			responseData = await response.json();
		} catch (jsonError) {
			throw new Error(`Invalid JSON response from API (${response.status})`);
		}

		if (!response.ok) {
			const errorMessage =
				responseData?.message ||
				`Request failed with status ${response.status}`;

			if (response.status === 401) {
				return { status: 401, data: null, message: 'Unauthorized' };
			}

			throw new Error(errorMessage);
		}

		if (
			!responseData ||
			typeof responseData !== 'object' ||
			!('data' in responseData)
		) {
			throw new Error('Invalid API response structure');
		}

		if (responseData.result === 2) {
			throw new Error(responseData.message || 'Unexpected API error');
		}

		return {
			status: 200,
			data: responseData.data,
			message: responseData.message || 'Success',
		};
	} catch (error: unknown) {
		const errorMessage =
			error instanceof Error ? error.message : 'Unknown error occurred';

		throw new Error(errorMessage);
	}
};
